<template>
  <v-container fluid>
    <p class="text-h5 text-left">권한 관리</p>
    <div style="text-align: left; border: 1px solid #aaaaaa; padding: 20px">
      <label style="display: inline-block; width: 100px; text-align: right;">권한명</label>
      <input v-model="param.authName" type="text" style="width:150px; margin: 0 10px" class="blue_input">
      <button type="button" class="pp_s" @click="search">검색</button>
    </div>

    <v-row>
      <v-col cols="8">
        <table class="popup-table mt-6" style="width: 100%">
          <colgroup>
            <col width="10%">
            <col width="20%">
            <col width="20%">
            <col width="20%">
            <col width="20%">
            <col width="10%">
          </colgroup>
          <thead>
          <tr>
            <th class="td_dark_blue">NO.</th>
            <th class="td_dark_blue">권한코드</th>
            <th class="td_dark_blue">권한명</th>
            <th class="td_dark_blue">설명</th>
            <th class="td_dark_blue">사용여부</th>
            <th class="td_dark_blue">
              <v-btn small elevation="0" color="primary" @click="addItem">추가</v-btn>
            </th>
          </tr>
          </thead>
          <thead v-if="loading">
          <tr>
            <th colspan="6" class="td_loading">
              <v-progress-linear
                indeterminate
                color="#0f2585"
              ></v-progress-linear>
            </th>
          </tr>
          </thead>
          <tbody v-if="items.length > 0">
          <template v-for="(item,index) in items">
            <tr :key="index" :class="selectedIndex === index ? 'selected' : ''">
              <td class="td_blue cen bo" @click="itemSelect(item, index)">{{ index + 1 }}</td>
              <td class="td_blue cen bo" @click="itemSelect(item, index)">{{ item.authCode }}</td>
              <td class="td_blue cen bo" @click="itemSelect(item, index)">{{ item.authName }}</td>
              <td class="td_blue cen bo" @click="itemSelect(item, index)">{{ item.authDesc }}</td>
              <td class="td_blue cen bo" @click="itemSelect(item, index)">
                {{ item.useYn === 'Y' ? '사용' : '' }}
              </td>
              <td class="td_blue cen bo">
                <v-btn small elevation="0" color="#bfc2c5" @click="removeItem(item, index)">삭제</v-btn>
              </td>
            </tr>
          </template>
          </tbody>
          <tbody v-else>
          <tr>
            <td class="no-data" colspan="6">데이터를 찾을 수 없습니다.</td>
          </tr>
          </tbody>

        </table>

        <table class="popup-table mt-6" style="width: 100%">
          <colgroup>
            <col width="20%">
            <col width="30%">
            <col width="20%">
            <col width="30%">
          </colgroup>
          <tbody>
          <tr>
            <th class="td_dark_blue"><label for="txtAuthCode">권한코드</label></th>
            <td class="td_blue cen bo">
              <input
                v-model="selectedItem.authCode"
                type="text"
                style="width:96%"
                class="blue_input"
                id="txtAuthCode"
              />
            </td>
            <th class="td_dark_blue"><label for="txtAuthDesc">설명</label></th>
            <td class="td_blue cen bo">
              <input
                v-model="selectedItem.authDesc"
                type="text"
                style="width:96%"
                class="blue_input"
                id="txtAuthDesc"
              />
            </td>
          </tr>
          <tr>
            <th class="td_dark_blue"><label for="txtAuthName">권한명</label></th>
            <td class="td_blue cen bo">
              <input
                v-model="selectedItem.authName"
                type="text"
                style="width:96%"
                class="blue_input"
                id="txtAuthName"
              />
            </td>
            <th class="td_dark_blue"><label for="txtUseYn">사용여부</label></th>
            <td class="td_blue cen bo">
              <input
                v-model="selectedItem.useYn"
                class="ml-4 mr-2"
                type="checkbox"
                id="txtUseYn"
                true-value="Y"
                false-value="N"
              />
            </td>
          </tr>
          </tbody>

        </table>
        <div class="text-right mt-2">
          <v-btn small class="mr-2" elevation="0" color="primary" @click="saveItem" :disabled="selectedIndex < 0">저장
          </v-btn>
        </div>
      </v-col>

      <v-col cols="4">
        <table class="popup-table mt-6" style="width: 100%">
          <colgroup>
            <col width="10%">
            <col width="20%">
            <col width="70%">
          </colgroup>
          <thead>
          <tr>
            <th class="td_dark_blue">NO.</th>
            <th class="td_dark_blue">
              <input
                v-model="checkAll"
                type="checkbox"
                true-value="Y"
                false-value="N"
              />
            </th>
            <th class="td_dark_blue">메뉴명</th>
          </tr>
          </thead>
          <thead v-if="menuLoading">
          <tr>
            <th colspan="3" class="td_loading">
              <v-progress-linear
                indeterminate
                color="#0f2585"
              ></v-progress-linear>
            </th>
          </tr>
          </thead>
          <tbody v-if="menuList.length > 0">
          <template v-for="(item,index) in menuList">
            <tr :key="index">
              <td class="td_blue cen bo">{{ index + 1 }}</td>
              <td class="td_blue cen bo">
                <input
                  v-model="item.chk"
                  type="checkbox"
                  true-value="Y"
                  false-value="N"
                />
              </td>
              <td class="td_blue cen bo">{{ item.menuName }}</td>
            </tr>
          </template>
          </tbody>
          <tbody v-else>
          <tr>
            <td class="no-data" colspan="3">데이터를 찾을 수 없습니다.</td>
          </tr>
          </tbody>

        </table>
        <div class="text-right mt-2">
          <v-btn small class="mr-2" elevation="0" color="primary" @click="saveMenuList" :disabled="selectedIndex < 0 || selectedItem.isNew">저장
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'MenuManage',
  data() {
    return {
      param: {
        authName: null
      },
      loading: false,
      menuLoading: false,
      items: [],
      menuList: [],
      selectedItem: {},
      selectedIndex: -1,
      checkAll: 'N'
    }
  },
  watch: {
    checkAll(val) {
      for (let menu of this.menuList) {
        menu.chk = val
      }
    }
  },
  mounted() {
    this.search()
  },
  methods: {
    search() {
      this.loading = true
      this.$http.get('/api/sys/auth', {params: this.param})
      .then(({ data }) => {
        this.items = data
        this.loading = false
        if (data.length > 0) {
          this.selectedItem = this.items[0]
          this.selectedIndex = 0
          this.searchMenuList()
        } else {
          this.selectedItem = {}
          this.selectedIndex = -1
        }
      })
    },
    searchMenuList() {
      this.menuLoading = true
      this.$http.get('/api/sys/menu/auth', {params: {authCode: this.selectedItem.authCode}})
      .then(({ data }) => {
        this.menuList = data
        this.menuLoading = false
      })
    },
    itemSelect(item, index) {
      this.selectedItem = item
      this.selectedIndex = index
      this.searchMenuList()
    },
    addItem() {
      const newItem = {order: this.items.length + 1, isNew: true}
      this.items.push(newItem)
      this.selectedItem = this.items[this.items.length - 1]
      this.selectedIndex = this.items.length - 1
    },
    saveItem() {
      this.$http.post('/api/sys/auth', this.selectedItem)
      .then(({ data }) => {
        if (data === 'success') {
          alert('저장되었습니다.')
          this.search()
        }
      })
    },
    removeItem(item, index) {
      if (item.isNew) {
        this.items.splice(index, 1)
      } else {
        if (confirm('선택한 항목을 삭제하겠습니까?')) {
          this.$http.post('/api/sys/auth/remove', item)
          .then(({data}) => {
            if (data === 'success') {
              alert('삭제되었습니다.')
              this.search()
            }
          });
        }
      }
    },
    saveMenuList() {
      this.$http.post('/api/sys/menu/auth', this.menuList)
      .then(({ data }) => {
        if (data === 'success') {
          alert('저장되었습니다.')
          this.searchMenuList()
        }
      })
    },
  }
}
</script>
